/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ActivityFn, pathToActiveWhen } from "single-spa";
import { isPageWithoutAuthentication } from "./pagesWithoutAuthentication";

export const pagePrefixesWithoutVerticalSpaceAfterHeaderAndBeforeFooter: string[] = [
  "/some/dummy/page/without/space/to/make/coverage/possible" //TODO replace when we have a production prefix
];

const activityFunctionsForPagesWithoutVerticalSpaceAfterHeaderAndBeforeFooter: ActivityFn[]
    = pagePrefixesWithoutVerticalSpaceAfterHeaderAndBeforeFooter.map(path => pathToActiveWhen(path));

export const isPageWithoutVerticalSpaceAfterHeaderAndBeforeFooter: (location?: Location) => boolean
    = (location = window.location) => activityFunctionsForPagesWithoutVerticalSpaceAfterHeaderAndBeforeFooter
    .some(activityFn => activityFn(location));

export function manageVerticalSpaceAfterHeaderAndBeforeFooter(isAuthenticated: boolean) {
  const addVerticalSpace = isAuthenticated && !isPageWithoutVerticalSpaceAfterHeaderAndBeforeFooter()
      || !isAuthenticated && isPageWithoutAuthentication();

  document.getElementById("gk_p-frame-main")
      .classList
      .toggle("gk_p-main-margin", addVerticalSpace);
}
