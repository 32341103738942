/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { LifeCycles, registerApplication, SingleSpaCustomEventDetail } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { AppProps as OrdermanagementMailRootProps } from "@am/ordermanagement-mail";
import { trackPageCall } from "@gkuis/gkp-base-widgets";

export const ordermanagementMailApplicationName = "@am/ordermanagement-mail";

export function registerApplicationOrdermanagementMail(authenticationManager: AuthenticationManager) {
  window.addEventListener("single-spa:routing-event", (evt: CustomEvent<SingleSpaCustomEventDetail>) => {
    const newUrl = evt.detail.newUrl;
    if (newUrl.includes("/ordermanagement-mail/ordercreate")) {
      trackPageCall("Brief:Auftragsmanagment:Auftrag erstellen");
    } else if (newUrl.includes("/ordermanagement-mail/ordersearch")) {
      trackPageCall("Brief:Auftragsmanagment:Auftragssuche");
    }
  });

  const ordermanagementRootProps: OrdermanagementMailRootProps = {
    authenticationManager
  };

  registerApplication({
    name: ordermanagementMailApplicationName,
    app: () => System.import<LifeCycles>("@am/ordermanagement-mail"),
    activeWhen: ["/ordermanagement-mail"],
    customProps: {
      ...ordermanagementRootProps,
      domElementGetter: reactDomElementGetter
    },
  });
}