/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Reporting, ReportingRootProps } from "@gkuis/gkp-reporting-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function configureApplicationReporting() {
  Reporting.configure({
    resourceDataBaseUri: `${process.env.REPORTING_BASE_URI}/v1`,
    validationRulesBaseUri: `${process.env.REPORTING_BASE_URI}/v1`,
    deliveryMonitorUri: `${process.env.REPORTING_BASE_URI}/v1/deliverymonitor`,
    co2ReportingUri: `${process.env.REPORTING_BASE_URI}/v1/co2reporting`,
    orderManagementMailUri: `${process.env.REPORTING_BASE_URI}/v1/ordermanagementmail`
  });
}

export function registerApplicationReporting(authenticationManager: AuthenticationManager) {
  const reportingRootProps: ReportingRootProps = {
    authenticationManager,
    reportingConfiguration: Reporting.configure()
  };

  registerApplication({
    name: "@gkuis/gkp-reporting-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-reporting-ui"),
    activeWhen: ["/reporting"],
    customProps: {
      ...reportingRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}
