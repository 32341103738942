/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcResponseplus } from "@gkuis/gkp-customerconfigresponseplus-ui";

export function configureApplicationCcResponseplus() {
  CcResponseplus.config = {
    baseUri: process.env.CCRESPONSEPLUS_BASE_URI!
  };
}
