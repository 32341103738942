/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcCommissionedDataProcessing } from "@gkuis/gkp-customerconfigcommissioneddataprocessing-ui";

export function configureApplicationCcCommissionedDataProcessing() {
  CcCommissionedDataProcessing.config = {
    baseUri: process.env.CCCOMMISSIONEDDATAPROCESSING_BASE_URI!
  };
}
