/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { RootProps } from "@gkuis/gkp-header-ui";
import { getTertiaryNavigation, primaryNavigation } from "../navigation";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function registerApplicationHeader(authenticationManager: AuthenticationManager) {
  const headerRootProps: RootProps = {
    mode: "external",
    authenticationManager,
    primaryNavigation,
    tertiaryNavigation: getTertiaryNavigation(authenticationManager),
    branding: {
      name: "brandName",
      logoRedirectUri: "/"
    }
  };

  registerApplication({
    name: "@gkuis/gkp-header-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-header-ui"),
    activeWhen: ["/"],
    customProps: {
      ...headerRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}


