/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Packstation, PackstationConfiguration, PackstationRootProps } from "@gkuis/gkp-packstation-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EnvConfig } from "../types/EnvConfig";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function configureApplicationPackstation() {
  Packstation.configuration = {
    baseUri: process.env.PACKSTATION_BASE_URI!,
  };
}

export function registerApplicationPackstation(authenticationManager: AuthenticationManager, envConfig: EnvConfig) {
  const packstationRootProps: PackstationRootProps = {
    authenticationManager,
    packstationConfiguration: Packstation.configuration,
    cmsContentRoot: envConfig.cmsContentRoot
  };

  registerApplication({
    name: "@gkuis/gkp-packstation-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-packstation-ui"),
    activeWhen: ["/packstation"],
    customProps: {
      ...packstationRootProps,
      domElementGetter: reactDomElementGetter
    }
  });
}