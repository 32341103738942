/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import i18n from "i18next";
import ChainedBackend, { ChainedBackendOptions } from "i18next-chained-backend";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { logger } from "@gkuis/gkp-authentication";

const loadPathSuffix: string = "localizations/{{lng}}/{{ns}}.json"
const addPath: string = "/localizations/add/{{lng}}/{{ns}}"
const httpBackendOptionsExternalFrame: HttpBackendOptions = {
  loadPath: `/${loadPathSuffix}`,
  addPath: addPath,
};
const httpBackendOptionsLq: HttpBackendOptions = {
  loadPath: `${process.env.LQ_FE}/${loadPathSuffix}`,
  addPath: addPath,
};

const chainedBackendOptions: ChainedBackendOptions = {
  backends: [
      HttpBackend,
      HttpBackend,
  ],
  backendOptions: [
    httpBackendOptionsExternalFrame,
    httpBackendOptionsLq
  ]
}

export const i18nInitPromise = i18n
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      debug: logger.isEnabled(),
      // resources, //some error messages to show iff localizations could not be loaded? or partialBundledLanguages?
      lng: "de",
      // fallbackLng: "dev", // see https://www.i18next.com/principles/fallback#fallback-to-different-languages
      supportedLngs: ["de", "en"],
      ns: "common",
      fallbackNS: "common",

      saveMissing: true, //would be very cool to find missing localization keys. But what about script kiddies?
      saveMissingTo: "current",
      saveMissingPlurals: true,
      //missingKeyHandler: send to somewhere to get it into graylog?
      appendNamespaceToMissingKey: true,

      interpolation: {
        escapeValue: false // react already safes from xss
      },
      react: {
        nsMode: "fallback"
      },

      backend: chainedBackendOptions
    });

export * as i18n from "i18next";
