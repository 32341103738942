/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ReportingProactive, ReportingProactiveRootProps } from "@gkuis/gkp-reportingproactive-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationReportingProactive() {
  ReportingProactive.configure({
    baseUriV1: `${process.env.REPORTINGPROACTIVE_BASE_URI}/v1`,
    baseUriV2: `${process.env.REPORTINGPROACTIVE_BASE_URI}/v2`,
    resourceDataBaseUri: `${process.env.REPORTINGPROACTIVE_BASE_URI}/v1`,
    validationRulesBaseUri: `${process.env.REPORTINGPROACTIVE_BASE_URI}/v1`,
    baseUriInternal: ""
  });
}

export function registerApplicationReportingProactive(authenticationManager: AuthenticationManager, envConfig: EnvConfig) {
  const reportingProactiveRootProps: ReportingProactiveRootProps = {
    authenticationManager,
    reportingProactiveConfiguration: ReportingProactive.configure(),
    cmsContentRoot: envConfig.cmsContentRoot
  };

  registerApplication({
    name: "@gkuis/gkp-reportingproactive-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-reportingproactive-ui"),
    activeWhen: ["/reportingproactive"],
    customProps: {
      ...reportingProactiveRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}