/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcTrackAndTrace } from "@gkuis/gkp-customerconfigtrackandtrace-ui";

export function configureApplicationCcTrackAndTrace() {
  CcTrackAndTrace.config = {
    baseUri: process.env.CCTRACKANDTRACE_BASE_URI!
  };
}
