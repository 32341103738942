/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { LQ, LQRootProps } from "@gkuis/gkp-gefahrgut-ui";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationLimitedQuantities(envConfig: EnvConfig) {
  LQ.config = {
    baseUri: process.env.LQ_BASE_URI!,
    cmsContentRoot: envConfig.cmsContentRoot
  };
}

export function registerApplicationLimitedQuantities(authenticationManager: AuthenticationManager) {
  const lqRootProps: LQRootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@gkuis/gkp-gefahrgut-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-gefahrgut-ui"),
    activeWhen: ["/lq"],
    customProps: {
      ...lqRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}