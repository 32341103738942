/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { trackPageCall } from "@gkuis/gkp-base-widgets";
import { LifeCycles, registerApplication, SingleSpaCustomEventDetail } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { RootProps } from "@automationcheck/gkp-automationcheck-ui/src/dashboardEntryPoint";

export function registerApplicationAutomationCheck(authenticationManager: AuthenticationManager) {
  window.addEventListener("single-spa:routing-event", (evt: CustomEvent<SingleSpaCustomEventDetail>) => {
    const newUrl = evt.detail.newUrl;
    if (newUrl.includes("/ac-web/wizard")) {
      trackPageCall("Brief:Automationscheck:Neue Prüfung starten");
    } else if (newUrl.includes("/ac-web/dashboard")) {
      trackPageCall("Brief:Automationscheck:Übersicht Prüfungen");
    }
  });

  const rootProps: RootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@automationcheck/gkp-automationcheck-ui",
    app: () => System.import<LifeCycles>("@automationcheck/gkp-automationcheck-ui"),
    activeWhen: ["/ac-web"],
    customProps: {
      ...rootProps,
      domElementGetter: reactDomElementGetter
    },
  });
}