/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ActivityFn, pathToActiveWhen } from "single-spa";

export const pagePrefixesWithoutAuthentication: string[] = [
  "/user/optin",
  "/user/optinsuccess",
  "/user/passwordchange",
  "/user/passwordchangesuccess",
  "/user/ammigration",
  "/logoutSuccess"
];

const activityFunctionsForPagesWithoutAuthentication: ActivityFn[] = pagePrefixesWithoutAuthentication.map(path => pathToActiveWhen(path));

export const isPageWithoutAuthentication: (location?: Location) => boolean
    = (location = window.location) => activityFunctionsForPagesWithoutAuthentication.some(activityFn => activityFn(location));