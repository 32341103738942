/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager, Language, logger } from "@gkuis/gkp-authentication";
import { TrackingDataLayer, TrackingRelevantInformation } from "./types/Tracking";
import { autorun, reaction } from "mobx";

declare global {
  interface Window {
    /**
     * s. Web Tracking Implementation Guide v2
     */
    launchLoaded?: boolean;

    /* partial OneTrust Banner SDK API */
    OneTrust?: {
      ToggleInfoDisplay(): void,
      changeLanguage(lang: Language): void
    };
    /**
     * OneTrust
     * The wrapper is executed on each page load, or whenever the user saves changes to the privacy settings in the Preference Center.
     */
    OptanonWrapper?: () => unknown;

    /**
     * Our public interface for AdobeLaunch
     */
    tracking?: {
      dataLayer: TrackingDataLayer;
    };
  }
}

const EVENT_CUSTOM_PAGE_VIEW = "customPageView";
const EVENT_FEATURE_USED = "featureUsed";
const LOG_MODULE = "TrackingManager";

export class TrackingManager {
  private messageBuffer: MessageEvent[] = [];
  private dataLayer: TrackingDataLayer;

  constructor(
      private readonly trackingUrl: string,
      private readonly domainScriptId: string,
      private readonly authenticationManager: AuthenticationManager,
      private readonly allowedExternalOrigins: string[]
  ) {
    this.initTracking();
    this.initDataLayer();
    autorun(() => {
      document.documentElement.lang = this.authenticationManager.language;
      window.OneTrust?.changeLanguage(this.authenticationManager.language);
    });
  }

  private initTracking() {
    window.addEventListener("message", (event: MessageEvent) => this.handleTrackingMessage(event));
    const onLaunchLoaded = () => {
      document.body.removeEventListener("launchLoaded", onLaunchLoaded);
      const messagesToSend = this.messageBuffer;
      this.messageBuffer = [];
      logger.log(LOG_MODULE, "launchLoaded Event received or detected, sending stored messages: ", messagesToSend.length);
      messagesToSend.forEach((m: MessageEvent) => this.handleTrackingMessage(m));
    };
    document.body.addEventListener("launchLoaded", onLaunchLoaded, false);

    const scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.src = this.trackingUrl;
    scriptTag.crossOrigin = "anonymous";
    scriptTag.async = true;
    document.head.appendChild(scriptTag);
  }

  private initDataLayer() {
    window.tracking = window.tracking || {} as { dataLayer: TrackingDataLayer };
    const dataLayerGetter = () => this.dataLayer; //bind this
    Object.defineProperty(window.tracking, "dataLayer", {
      get(): TrackingDataLayer { return dataLayerGetter(); } //bind this and have types correct
    });

    if (!TrackingManager.isTrackingConsented()) {
      return;
    }

    this.dataLayer = {
      page: {
        pageInfo: {}
      },
      anonymousProfile: [{
        user: {
          loginStatus: "Not-logged-in"
        }
      }]
    };

    //reset on user change/logout
    autorun(() => {
      if (!this.authenticationManager.authenticated) {
        //remove everything except loginStatus
        this.dataLayer.anonymousProfile[0].user = {loginStatus: this.dataLayer.anonymousProfile[0].user.loginStatus};
      }
    });

    //language
    autorun(() => this.dataLayer.page.pageInfo.language = this.authenticationManager.language);

    //loginStatus and gkpFunctions
    const onAuthOrAuthRefreshSuccess = async () => {
      this.dataLayer.anonymousProfile[0].user.loginStatus = "Logged-in";
      this.dataLayer.anonymousProfile[0].user.gkpFunctions = await this.authenticationManager.getScopes();
    }
    this.authenticationManager.addEventListener("onAuthSuccess", onAuthOrAuthRefreshSuccess);
    this.authenticationManager.addEventListener("onAuthRefreshSuccess", onAuthOrAuthRefreshSuccess);
    this.authenticationManager.addEventListener("onManualLogout", () => {
      this.dataLayer.anonymousProfile[0].user.loginStatus = "Logged-out";
    });
    this.authenticationManager.addEventListener("onRefreshTokenExpired", () => {
      this.dataLayer.anonymousProfile[0].user.loginStatus = "Expired";
    });

    //tracking relevant information from user-ext
    reaction(
        () => this.authenticationManager.authenticatedSubject,
        async () => {
          if (this.authenticationManager.authenticated) {
            try {
              const response = await fetch(
                  `${process.env.USER_EXTERNAL_BASE_URI}/v1/user/mydata/trackingrelevantinformation`,
                  {
                    headers: {
                      Accept: "application/json",
                      Authorization: `Bearer ${await this.authenticationManager.getAccessToken()}`
                    }
                  }
              );
              if (!response.ok) {
                logger.log("Received error response", response.status);
                return Promise.reject(await response.json());
              }

              const trackingRelevantInformation: TrackingRelevantInformation = await response.json();
              this.dataLayer.anonymousProfile[0].user = {...this.dataLayer.anonymousProfile[0].user, ...trackingRelevantInformation};
            } catch (error) {
              logger.log(LOG_MODULE, "Failed loading tracking relevant information", error);
            }
          }
        },
        {fireImmediately: true}
    );
  }

  private static isTrackingConsented() {
    const consentCookieValueEncoded = document.cookie
            .split(";")
            .map(c => c.trim())
            .find(c => c.startsWith("OptanonConsent=")) //may return undefined
            ?.replace(/^OptanonConsent=/, "")
        ?? "";
    const groupsEncoded = consentCookieValueEncoded
            .split("&")
            .find(p => p.startsWith("groups="))
            ?.replace(/^groups=/, "")
        ?? "";
    const groups = decodeURIComponent(groupsEncoded);
    return groups.includes("C0002:1");
  }

  public loadAndShowConsentDialog() {
    try {
      if (window.OneTrust) {
        window.OneTrust.ToggleInfoDisplay();
      } else {
        const origWrapper: (() => unknown) | undefined = window.OptanonWrapper;
        window.OptanonWrapper = () => {
          window.OptanonWrapper = origWrapper;
          try {
            origWrapper?.();
            window.OneTrust?.ToggleInfoDisplay();
          } catch (e) {
            logger.error("error in callback while triggering consent display", e);
          }
        };
        const scriptTag = document.createElement("script");
        scriptTag.type = "text/javascript";
        scriptTag.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
        scriptTag.crossOrigin = "anonymous";
        scriptTag.async = true;
        scriptTag.charset = "UTF-8";
        scriptTag.setAttribute("data-document-language", "true");
        scriptTag.setAttribute("data-domain-script", this.domainScriptId); //TODO wie bekommen wir die script id+src-url von adobe?
        document.head.appendChild(scriptTag);
      }
    } catch (e) {
      logger.error("error while showing cookie consent dialog", e);
    }
  }

  private handleTrackingMessage(event: MessageEvent) {
    const eventID = event.data.event_id;
    const eventData = event.data.data;
    if (eventID !== EVENT_CUSTOM_PAGE_VIEW && eventID !== EVENT_FEATURE_USED) {
      return;
    }
    const hasAllowedOrigin = (event.origin === window.location.origin || this.allowedExternalOrigins.includes(event.origin));
    if (!hasAllowedOrigin) {
      logger.log(LOG_MODULE, "trackingmessage received, but not from an allowed origin - " + event.origin);
      return;
    }
    if (!window.launchLoaded) {
      if (this.messageBuffer.length < 10) {
        logger.log(LOG_MODULE, "trackingmessage received, but not launchLoaded, persisting");
        this.messageBuffer.push(event);
      } else {
        logger.log(LOG_MODULE, "no launchLoaded, trackingmessage buffer full, dropping event");
      }
      return;
    }
    document.dispatchEvent(new CustomEvent(eventID, {detail: eventData}));
    logger.log(LOG_MODULE, `${eventID} CustomEvent created: ${JSON.stringify(event.data)}`);
  }
}
