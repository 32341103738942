/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcBilling } from "@gkuis/gkp-customerconfigbilling-ui";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationCcBilling(envConfig: EnvConfig) {
  CcBilling.config = {
    baseUri: process.env.CCBILLING_BASE_URI!,
    cmsContentRoot: envConfig.cmsContentRoot
  };
}
