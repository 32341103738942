/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcTrackmail } from "@gkuis/gkp-customerconfigtrackmail-ui";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationCcTrackmail(envConfig: EnvConfig) {
  CcTrackmail.config = {
    baseUri: process.env.CCTRACKMAIL_BASE_URI!,
    cmsContentRoot: envConfig.cmsContentRoot
  };
}
