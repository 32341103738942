/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CustomerSpecificInformation } from "@gkuis/gkp-customerspecificinformation-ui";

export function configureApplicationCustomerSpecificInformation() {
  CustomerSpecificInformation.configure({
    resourceDataBaseUri: `${process.env.CSI_BASE_URI}/v1`,
    validationRulesBaseUri: `${process.env.CSI_BASE_URI}/v1`,
    baseUri: `${process.env.CSI_BASE_URI}/v1`,
  });
}
