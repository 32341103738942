/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcPackstation } from "@gkuis/gkp-customerconfigpackstation-ui";

export function configureApplicationCcPackstation() {
  CcPackstation.config = {
    baseUri: process.env.CCPACKSTATION_BASE_URI!
  };
}
