/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { LoginPageRootProps } from "@gkuis/gkp-loginpage-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EnvConfig } from "../types/EnvConfig";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { isPageWithoutAuthentication } from "../pagesWithoutAuthentication";

export function registerApplicationLoginPage(authenticationManager: AuthenticationManager, envConfig: EnvConfig) {
  const loginPageRootProps: LoginPageRootProps = {
    authenticationManager,
    mode: "external",
    cmsContentRoot: envConfig.cmsContentRoot
  };

  registerApplication({
    name: "@gkuis/gkp-loginpage-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-loginpage-ui"),
    activeWhen: [(location: Location) => !isPageWithoutAuthentication(location)],
    customProps: {
      ...loginPageRootProps,
      domElementGetter: reactDomElementGetter
    }
  });
}
