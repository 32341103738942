/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcShipMail } from "@gkuis/gkp-customerconfigshipmail-ui";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationCcShipMail(envConfig: EnvConfig) {
  CcShipMail.config = {
    baseUri: process.env.CCSHIPMAIL_BASE_URI!,
    cmsContentRoot: envConfig.cmsContentRoot
  };
}
